#refund_layout h4 {
    margin-top: 30px;
    font-size: 25px;
}
#refund_layout ul li {
    font-size: 20px;
    padding: 10px 0;
}
#refund_layout table {
    border-collapse: collapse; border: 1px solid #000000;
    font-size: 20px;
}
#refund_layout table td {
    border: 1px solid #000000;
    width: 33%;
    padding: 10px;
}

@media all and (max-width: 768px) {
    #refund_layout h4 {
        margin-top: 30px;
        font-size: 16px;
    }
    #refund_layout ul li {
        font-size: 16px;
    }

    #refund_layout ul li h5 {
        font-size: 16px;
    }
    #refund_layout ul li p {
        font-size: 14px;
    }

    #refund_layout table {
        font-size: 14px;
    }
    #refund_layout table td {
        padding: 5px;
    }
}