.board_table_container {
    width: 100%; height: 100%;
}

.board_thead {
    background: #FFFBFA;
    border-top: 2px solid #AB5476;
    border-bottom: 1px solid #EEEEEE;
}
.board_thead td {
    padding: 10px 0;
    text-align: center;
    font-size: 18px; font-weight: 700;
}

.board_tbody .board_tbody_row {
    cursor: pointer;
}
.board_tbody .board_tbody_row:hover {
    background: #FFFBFA;
}
.board_tbody td {
    border-bottom: 1px solid #EEEEEE;
    text-align: center;
    font-size: 16px;
    height: 50px;
}
.board_tbody td.board_col_title {
    text-align: left;
    display: flex; align-items: center;
    position: relative; top: 0; left: 0;
    width: 100%;
}
.board_tbody td.board_col_title span {
    display: inline-block;
    margin-right: 5px; padding: 2px 5px;
    font-size: 12px;
    color: #FFFFFF;
}
.board_tbody td.board_col_title span.task_similarity {
    position: absolute; top: 50%; right: 0;
    transform: translateY(-50%);
    color: #FAD200;
    font-size: 14px; font-weight: 700;
}

.board_tbody .board_empty {
    height: 250px;
}
.board_tbody .board_empty p {
    font-size: 18px;
}


@media all and (max-width: 768px) {
    .board_thead td {
        font-size: 14px;
    }

    .board_tbody td {
        font-size: 12px;
        height: 40px;
    }
}