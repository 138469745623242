* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
/* 스타일 1번 */
.selectBox01 {
    position: relative;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
}

.selectBox01 label {
    display: inline-block;
    margin-bottom: 10px;

    /* font-size: ;
    font-weight: ;
    font-family: ;
    font-style: ;
    color: ; */
}

.selectBox01 ul {
    list-style: none;
    position: relative;
    margin: 0;
}

.selectBox01 ul .ulCon {
    border-radius: 4px;
    display: flex;
    align-items: center;
    /* padding: 4.7% 5.8%; */
    box-sizing: border-box;
}

.selectBox01 ul .ulCon p {
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    padding: 0;
    margin: 0;
}

.selectBox01 ul .ulCon div {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
}

.selectBox01 ul .liCon {
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);

    overflow-y: scroll;
    z-index: 10;

    position: absolute;
    top: 0;
    width: 100%;
}

.selectBox01 ul .liCon li {
    display: flex;
    align-items: center;
    /* padding: 4.7% 5.8%; */
    box-sizing: border-box;
    background: #FFFFFF;
}

.selectBox01 ul .liCon li:hover {
    background: #F5FBFF;
}

.selectBox01 ul .liCon li:last-child {
    border-bottom: none;
}

.selectBox01 .spanText {
    margin-top: 5px;
    height: 16px;
    display: flex;
    align-items: center;
}

.selectBox01 .spanText p {
    font-size: 12px;
}

.selectBox01 .spanText span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9E9E9E;
    display: inline-block;
    padding-left: 5px;
}

@keyframes rotate  {
    100% {
        transform: rotate(360deg);
    }
}

/* 스타일 2번 */
.selectBox02 {
    position: relative;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
}

.selectBox02 label {
    display: inline-block;
    margin-bottom: 10px;
}

.selectBox02 ul {
    list-style: none;
    position: relative;
}

.selectBox02 ul .ulCon {
    display: flex;
    align-items: center;
    /* padding: 4.7% 5.8%; */
    box-sizing: border-box;
}

.selectBox02 ul .ulCon p {
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #03A9F4;
}

.selectBox02 ul .ulCon div {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
}

.selectBox02 ul .liCon {
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);

    overflow-y: scroll;

    position: absolute;
    top: 0;
    width: 100%;
}

.selectBox02 ul .liCon li {
    display: flex;
    align-items: center;
    /* padding: 4.7% 5.8%; */
    box-sizing: border-box;
    background: #FFFFFF;
}

.selectBox02 ul .liCon li:hover {
    background: #F5FBFF;
}

.selectBox02 .spanText {
    margin-top: 5px;
    height: 16px;
    display: flex;
    align-items: center;
}

.selectBox02 .spanText p {
    font-size: 12px;
}

.selectBox02 .spanText span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9E9E9E;
    display: inline-block;
    padding-left: 5px;
}