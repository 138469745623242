.ant-tabs-nav::before {
    border-bottom: 0px solid #F0F0F0 !important;
}
.ant-tabs-tab.ant-tabs-tab-active div {
    color: #000 !important;
}
.ant-tabs-tab div:hover {
    color: #000 !important;
}

.ant-tabs-ink-bar {
    background: #000 !important;
}