.button-comp-layout {
    margin: 0 auto;
}

.button-comp-layout button {
    background: none;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 20px;
    border-radius: 4px;
}