.board_detail_layout {
    width: 100%;
}


/* 타이틀 */
.board_detail_title {
    padding: 20px 10px;
    background: #FFFBFA;
    border-top: 2px solid #AB5476; border-bottom: 1px solid #EEEEEE;
    display: flex; justify-content: space-between; align-items: center;
}
.board_detail_title h4 {
    font-size: 24px; font-weight: 700;
    margin: 0;
}
.board_detail_title span {
    font-size: 14px;
    /* color: #FFFFFF; */
    border-radius: 5px;
}

/* 작성자 정보 */
.board_detail_info {
    padding: 10px;
    display: flex; justify-content: flex-start; align-items: center;
    border-bottom: 1px solid #EEEEEE;
}
.board_detail_info h5 {
    font-size: 18px; font-weight: 500;
    margin: 0;
}
.board_detail_info p {
    font-size: 14px;
    color: #9E9E9E;
}


/* 게시글 내용 */
.board_detail_content_container {
    border-bottom: 1px solid #AB5476;
}
.board_detail_content_container .board_detail_content {
    padding: 30px 10px;
    word-break: break-all;
}
.board_detail_content_container .board_detail_file {
    padding: 10px 10px 0px;
    border-top: 1px solid #EEEEEE;
}
.board_detail_content_container .board_detail_file ul {
    display: flex; align-items: center;
}
.board_detail_content_container .board_detail_file ul li {
    font-size: 16px; padding: 10px 0px;
}
.board_detail_content_container .board_detail_file ul li:nth-child(1) {
    font-weight: 700;
    border-right: 1px solid #EEEEEE;
    padding-right: 10px;
    word-break: keep-all;
}
.board_detail_content_container .board_detail_file ul li:nth-child(2) {
    padding-left: 10px;
    width: 100%;
}
.board_detail_content_container .board_detail_file ul li:nth-child(2) p {
    width: 100%;
    display: flex; justify-content: space-between; align-items: center;
}
.board_detail_content_container .board_detail_file ul li:nth-child(2) p span {
    color: #FB4E4E;
}


@media all and (max-width: 768px) {
    /* 타이틀 */
    .board_detail_title {
        padding: 15px 5px;
    }
    .board_detail_title h4 {
        font-size: 18px;
    }
    .board_detail_title span {
        font-size: 14px;
        /* color: #FFFFFF; */
        border-radius: 5px;
    }


    /* 작성자 정보 */
    .board_detail_info {
        padding: 10px;
    }
    .board_detail_info h5 {
        font-size: 16px;
    }
    .board_detail_info p {
        font-size: 12px;
    }
}