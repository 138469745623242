
.profile_info_layout .profile_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #000000;
}
.profile_info_layout .profile_title span {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #9E9E9E;
    margin-left: 8px;
}
.info_title {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #000000;
}


@media all and (max-width: 768px) {
    .info_title {
        font-size: 16px;
    }
}

