/* 입력폼 */
.board_form {
    border: 1px solid #BDBDBD;
}
.board_form > td {
    padding: 10px;
}
.board_form > td:nth-child(1) {
    width: 10%; height: 100%;
    background: #EEEEEE;
    border-right: 1px solid #BDBDBD;
}
.board_form > td:nth-child(1) p {
    font-size: 18px; font-weight: 700;
    text-align: center;
}
.board_form > td:nth-child(2) {
    width: 90%;
}
.board_form > td:nth-child(2) p {
    font-size: 18px;
}

.board_file_input {
    display: flex; align-items: center;
}
.board_file_input p {
    font-size: 18px;
    padding-right: 10px;
}
.board_file_input button {
    border: 1px solid #EEEEEE; border-radius: 5px;
    background: none;
    width: 25px; height: 25px;
    display: flex; align-items: center; justify-content: center;
    cursor: pointer;
}


@media all and (max-width: 768px) {
    /* 입력폼 */
    .board_form > td:nth-child(1) {
        width: 20%;
    }
    .board_form > td:nth-child(1) p {
        font-size: 16px;
    }
    .board_form > td:nth-child(2) {
        width: 80%;
    }
    .board_form > td:nth-child(2) p {
        font-size: 16px;
    }
}