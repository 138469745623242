.slide-layout {
    width: 100%; height: 100%;
    position: relative; top: 0; left: 0;
}
.slide-layout .slide-header {
    display: flex; align-items: center; justify-content: space-between;
    width: 100%;
}
.slide-layout .slide-header p {
    font-size: 24px; font-weight: 700;
}
.slide-layout .slide-header .slide-controller {
    display: flex; align-items: center; justify-content: center;
}
.slide-layout .slide-header .slide-controller button {
    border: none; background: none;
    width: 35px; height: 35px; border-radius: 5px;
    font-size: 16px; font-weight: 900;
    cursor: pointer;
}
.slide-layout .slide-header .slide-controller button:hover {
    border: 1px solid #AB5476;
    color: #AB5476;
}
.slide-layout .slide-header .slide-controller button:last-child {
    margin-left: 17px;
}

.slide-layout .slide-sub {
    margin-bottom: 30px;
}
.slide-layout .slide-sub p {
    font-size: 16px;
    margin-top: 16px;
}

.slide-layout .ant-card {
    background: transparent;
}
.slide-layout .ant-card:hover {
    border: 1px solid #EEEEEE; box-sizing: border-box;
}
.slide-layout .ant-card-hoverable {
    border: none;
    transition: box-shadow 0.3s;
}