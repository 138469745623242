#introduce_layout ul {
    margin-top: 30px;
}
#introduce_layout ul li {
    margin-bottom: 20px;
    font-size: 20px;
}
#introduce_layout ul li h5 {
    font-size: 20px;
}
#introduce_layout ul li p {
    font-size: 20px;
}

@media all and (max-width: 768px) {
    #introduce_layout ul li {
        margin-bottom: 20px;
        font-size: 16px;
    }

    #introduce_layout ul li h5 {
        font-size: 16px;
    }
    #introduce_layout ul li p {
        font-size: 14px;
    }
}