.ant-collapse-header {
    padding: 0 !important;
    position: relative;
}
.ant-collapse-content-box {
    padding: 0 !important;
}

.sidebar-container .ant-badge {
    width: 100% !important;
}

.ant-select-selection-item {
    color: #000000;
}