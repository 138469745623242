.notfound-layout {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #FFFFFF;
}
.notfound-container {
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.notfound-container div h4 {
    margin-top: 80px;
    font-size: 30px;
}
.notfound-container div p {
    font-size: 18px;
    color: #444444;
    margin: 10px 0;
}