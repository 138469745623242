.term-layout {
    list-style: none;
    margin: 0;
}

.term-layout label {
    display: inline-block;
    margin-bottom: 5px;
}

.term-list {
    background: #F5F5F5;
    padding: 5px;
}

.term-list li {
    color: #BDBDBD;
}