#journal-layout {
    width: 100%;
    margin: 0 auto;
}

#journal-layout h1 {
    font-size: 25px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
}

#journal-layout .journal-table01 {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}
#journal-layout .journal-table01 th {
    width: 20%;
    background: #DBE7F3;
    border: 1px solid #000;
    padding: 10px;
    font-size: 18px;
}
#journal-layout .journal-table01 td {
    width: 30%;
    border: 1px solid #000;
    padding: 10px;
    font-size: 18px;
}

#journal-layout .journal-table02 {
    margin-top: 30px;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}
#journal-layout .journal-table02 th {
    background: #DBE7F3;
    border: 1px solid #000;
    padding: 10px;
    font-size: 18px;
}
#journal-layout .journal-table02 td {
    border: 1px solid #000;
    padding: 10px;
    font-size: 18px;
    word-break: break-all;
    padding: 10px;
}
#journal-layout .journal-table02 td.pre-container {
    max-height: 200px;
    overflow: hidden;
}
#journal-layout .journal-table02 td.pre-container pre {
    white-space: pre-wrap;
    word-break: break-word;
    max-height: 200px;
    overflow: hidden;
    position: relative;
    margin: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
}
#journal-layout .journal-table02 td img {
    width: 100%; 
    height: 100%;
    object-fit: contain;
}
#journal-layout .journal-table02 td.journal-sign {
    background: #E0E0E0;
}
#journal-layout .journal-table02 td div {
    min-height: 50px;
    display: flex; justify-content: center; align-items: center;
}
#journal-layout .journal-table02 td.journal-sign-img img {
    width: 80%; height: 100%;
    object-fit: contain;
}
