.App {
  text-align: center;
}
/*header style*/
.header-main {
  position: relative;
}
.header-visual {
  display: flex;
  position: absolute;
  width: 100%;
  color: rgb(0, 0, 0);
  line-height: 4;
  border-bottom: 1px solid;
  z-index: 1;
}
.header-visual > li:first-child {
  padding-left: 20px;
  flex: 1;
}
.header-visual > li:last-child {
  padding-right: 10%;
  justify-content: space-around;
}
.header-visual > li {
  width: 50%;
  list-style: none;
  display: flex;
  justify-items: center;
  align-items: center;
}
/* img {
  width: 40px;
} */

/*Homepresenter style*/
.home-container {
  display: flex;
  flex-direction: column;
}
.home-visual {
  height: 200px;
  margin-top: 80px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}



.w100 { width: 100%; }
.w95 { width: 95%; }
.w90 { width: 90%; }
.w85 { width: 85%; }
.w80 { width: 80%; }
.w75 { width: 75%; }
.w70 { width: 70%; }
.w65 { width: 65%; }
.w60 { width: 60%; }
.w55 { width: 55%; }
.w50 { width: 50%; }
.w45 { width: 45%; }
.w40 { width: 40%; }
.w35 { width: 35%; }
.w30 { width: 30%; }
.w25 { width: 25%; }
.w20 { width: 20%; }
.w15 { width: 15%; }
.w10 { width: 10%; }


.mainColorText {
  color:#2699FB
}



.cusorOver,
.hover-opacity {
  cursor: pointer;
}

.cusorOver:hover {
  background: #f1f1f1;
}

.hover-opacity:hover {
  opacity: 0.7;
}



#popup_overlay {
  position: relative;
  top: -130px;
  left: 0;
  width: 100%;
  height: 0px;
  z-index: 3001;
  margin: 0 auto;
}

#popup_overlay h2 {
  overflow: hidden;
  position: absolute;
  font-size: 0;
  line-height: 0;
}
