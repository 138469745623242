/* 슬라이더 컨테이너의 기본 스타일 */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    touch-action: pan-y;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* 슬라이드 아이템의 기본 스타일 */
.slick-list {
    overflow: hidden;
}
.slick-slide {
    /* display: none; */
    float: left;
    height: 100%;
    min-height: 1px;
}

/* 활성화된 슬라이드 아이템의 스타일 */
.slick-slide.slick-active {
    display: block;
}


/* 슬라이드 컨텐츠 기본 스타일 */
.slide-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    top: 0; left: 0;
}

.slide-content img {
    width: 100%;
    height: 100%;
    filter: brightness(0.9);
}



.slide-controller-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.slide-controller-box .slide-controller {
    position: relative;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
.slide-controller-box .slide-controller .slide-controller-button {
    width: 200px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100px;
    position: absolute;
    bottom: 20%;
    left: 5%;
    z-index: 2500;
}
.slide-controller-box .slide-controller .slide-controller-button .controller-pagination {
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
}
.slide-controller-box .slide-controller .slide-controller-button .controller-pagination span {
    padding: 0px 5px;
    display: inline-block;
}
.slide-controller-box .slide-controller .slide-controller-button .controller-button {
    display: flex;
    margin-left: 5px;
}
.slide-controller-box .slide-controller .slide-controller-button .controller-button button {
    background: none;
    border: none;
    color: #FFFFFF;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    cursor: pointer;
}