/* 게시판 메뉴 컨테이너 */
.board_menu_container {
    padding-right: 20px;
}
/* /게시판 메뉴 컨테이너 */

/* 게시판 메뉴 타이틀 */
.board_menu_title h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    display: flex; align-items: center;
    color: #AB5476;
}
.board_menu_title h4 span {
    width: 5px; height: 32px;
    background: #AB5476;
    margin-right: 5px;
}
/* /게시판 메뉴 타이틀 */

/* 게시판 메뉴 리스트 */
.board_menu_list {
    margin: 0; margin-top: 20px;
}
.board_menu_list li {
    cursor: pointer;
}
.board_menu_list li a {
    color: #000;
    font-size: 18px;
    display: inline-block;
    width: 100%; height: 100%;
    padding: 15px 10px;
}
/* /게시판 메뉴 리스트 */