.popup_layout {
    position: absolute;
    border: 1px solid #000000;
}

.popup_layout h5 {
    padding: 0;
    margin: 0;
    height: 0;
    line-height: 0;
    overflow: hidden;
}

.popup_layout .popup_footer {
    width: 100%;
    padding: 10px;
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popup_layout .popup_footer button {
    background: none;
    border: none;
    color: #FFFFFF;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
}


@media all and (max-width: 768px) {
    #popup_overlay {
        top: -60px !important;
    }

    .popup_layout {
        top: 30px !important;
        left: 0px !important;
    }

    .popup_layout .popup_content {
        width: 100% !important;
        height: auto !important;
    }
}