#agency-search-popup {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#agency-search-popup .agency-search-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

#agency-search-popup .agency-list-box {
    width: 100%;
    height: 100%;
}

#agency-search-popup .agency-list-box .agency-list {
    width: 100%;
    height: 100%;
}

#agency-search-popup .agency-list-box .agency-list li {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
}

#agency-search-popup .agency-list-box .agency-list li h4 {
    font-size: 18px;
    margin-bottom: 5px;
}

#agency-search-popup .agency-list-box .agency-list li h4 span {
    font-size: 14px;
}

#agency-search-popup .agency-list-box .agency-list li button {
    border: 1px solid #EEEEEE;
    background: #FFFFFF;
    padding: 5px 10px;
    cursor: pointer;
    color: #000;
    transition: all 0.3s;
}
#agency-search-popup .agency-list-box .agency-list li button:hover {
    color: #03A9F4;
    border: 1px solid #03A9F4;
    transition: all 0.3s;
}