.ant-pagination-item-active a {
    color: #FFFFFF !important;
}

.ant-pagination-item-active {
    font-weight: 500;
    background: #E3A4AF !important;
    border-color: #E3A4AF !important;
}

.ant-pagination-item:hover {
    background: #E3A4AF !important;
    border-color: #E3A4AF !important;
}
.ant-pagination-item:hover a {
    color: #FFFFFF !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link {
    background: #E3A4AF !important;
    border-color: #E3A4AF !important;
    color: #FFFFFF !important;
}