.input-layout-background label {
    display: inline-block;
    margin-bottom: 10px;

    /* font-size: ;
    font-weight: ;
    font-family: ;
    font-style: ;
    color: ; */
}

.input-layout-background .input-layout {
    display: flex;
    align-items: center;

    border: 1px solid #BDBDBD;
    border-radius: 4px;
    background: #FFFFFF;
    /* padding: 4.7% 5.8%; */
}

.input-layout-background .input-layout:focus-within {
    border: 1px solid #03A9F4;
}

.input-layout-background .input-layout input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
}

.input-layout-background .span-text {
    margin-top: 5px;
    height: 16px;
    display: flex;
    align-items: center;
}

.input-layout-background .span-text p {
    font-size: 12px;
}

.input-layout-background .span-text span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9E9E9E;
    display: inline-block;
    padding-left: 5px;
}

@keyframes rotate  {
    100% {
        transform: rotate(360deg);
    }
}