.board_comment_layout {
    margin-top: 50px;
    border-top: 2px solid #AB5476;
}

/* 댓글 타이틀 */
.board_comment_title {
    font-size: 18px;
    padding: 10px;
    background: #FFFBFA;
    border-bottom: 1px solid #EEEEEE;
}
.board_comment_title h4 {
    margin: 0;
    font-weight: 700;
}


/* 댓글 인풋 */
.board_comment_form {
    display: flex; justify-content: space-between; align-items: center;
    border-bottom: 1px solid #EEEEEE;
}
.board_comment_form > div {
    padding: 10px;
    font-size: 16px; font-weight: 500;
}
.board_comment_form > div:nth-child(1) {
    width: 80px; 
    text-align: center;
}
.board_comment_form > div:nth-child(2) {
    flex: 1;
}


/* 댓글목록 */
.board_comment_list_item {
    padding: 10px;
    display: flex;
}
.board_comment_list_item_info {
    margin-right: 10px;
}
.board_comment_list_item_comment {
    flex: 1;
}
.board_comment_list_item_comment h4 {
    margin-bottom: 5px;
    font-size: 16px; font-weight: 700;
}
.board_comment_list_item_comment h4 span {
    color: #757575;
    font-weight: 400; font-size: 14px;
}
.board_comment_list_item_comment > div.board_comment_list_item_container {
    width: 100%; min-height: 80px;
    background: #FAFAFA;
    padding: 10px;
    position: relative; top: 0; left: 0;
}
.board_comment_list_item_comment > div pre {
    white-space: pre-wrap;
    word-break: break-all;
    overflow: auto;
    font-size: 14px;
    margin: 0;
    width: 100%; height: 100%;
}
.board_comment_list_item_comment > div .board_comment_list_item_button {
    position: absolute; bottom: 0; right: 0;
}


.board_comment_list_item_form .board_comment_list_item_form_button {
    display: flex; justify-content: flex-start; align-items: center;
    margin-top: 10px;
}



/* 대댓글 */
.board_recomment_list_item {
    display: flex; justify-content: space-between; align-items: center;
}
.board_recomment_list_item > div:nth-child(2) {
    flex: 1;
}
.board_recomment_list_item > div:nth-child(2) > div:nth-child(2) {
    display: flex;
    margin-top: 10px;
}

.board_recommenet_list {
    padding-left: 100px;
    position: relative; top: 0; left: 0;
}
.board_recommenet_list .board_recommenet_list_icon {
    position: absolute; top: 10px; left: 80px;
    font-size: 18px;
    transform: rotate(90deg);
}

@media all and (max-width: 768px) {
    .board_comment_layout {
        margin-top: 30px;
    }

    /* 댓글 타이틀 */
    .board_comment_title {
        font-size: 16px;
    }

    /* 댓글 인풋 */
    .board_comment_form {
        flex-direction: column;
        align-items: flex-start;
    }
    .board_comment_form > div {
        padding: 5px 0px;
        font-size: 14px;
        width: 100%;
    }
    .board_comment_form > div:nth-child(1) {
        display: none;
    }
    .board_comment_form > div:nth-child(2) {
        flex: 1; 
    }
    .board_comment_form > div:nth-child(2) textarea {
        width: 100%;
    }
    .board_comment_form > div:nth-child(3) button {
        margin: 0 auto;
        width: 30%;
    }


    /* 댓글목록 */
    .board_comment_list_item_comment h4 {
        margin-bottom: 5px;
        font-size: 14px; font-weight: 700;
    }
    .board_comment_list_item_comment h4 span {
        font-size: 12px;
    }
}