.ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #AB5476 !important;
}

.ant-menu-submenu-selected {
    color: #AB5476 !important;
}

.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #AB5476 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #F2D3DB !important;
}