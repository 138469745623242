#privacy_layout h4 {
    margin-top: 30px;
    font-size: 25px;
}
#privacy_layout ul li {
    margin-bottom: 20px;
}
#privacy_layout ul li h5 {
    font-size: 20px;
}
#privacy_layout ul li p {
    font-size: 20px;
}

#privacy_layout ul li h6 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 0px;
}
#privacy_layout ul li dl {
    padding-left: 30px;
}

#privacy_layout ul li dt {
    font-size: 18px;
}

@media all and (max-width: 768px) {
    #privacy_layout h4 {
        margin-top: 30px;
        font-size: 16px;
    }
    #privacy_layout ul li {
        margin-bottom: 20px;
        font-size: 16px;
    }

    #privacy_layout ul li h5 {
        font-size: 16px;
    }
    #privacy_layout ul li p {
        font-size: 14px;
    }

    #privacy_layout ul li h6 {
        font-size: 16px;
    }

    #privacy_layout ul li dt {
        font-size: 14px;
    }
}