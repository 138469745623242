.taxbill-table {
    width: 100%;
    border-collapse: collapse;
}
.taxbill-table td, .taxbill-table th {
    border: 1px solid #000;
    padding: 10px;
}
.taxbill-table th {
    background: #F4F5F9;
}
.taxbill-table .type01 {
    background: #FFE1E1;
}
.taxbill-table .title.type01 {
    background: #FFB7B8;
}
.taxbill-table .type02 {
    background: #F3F5FF;
}
.taxbill-table .title.type02 {
    background: #C2D9F2;
}