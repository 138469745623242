.textareaBox textarea {
    width: 100%;
    height: 100%;
    /* padding: 2.3% 5.8%; */
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 24px; */
    color: #000000;
    font-style: normal;
    border-radius: 4px;

    background: transparent;
}

.textareaBox textarea:focus {
    border: 1px solid #03A9F4;
}

.textareaBox .spanText {
    margin-top: 5px;
    height: 16px;
    display: flex;
    align-items: center;
}

.textareaBox .spanText p {
    font-size: 12px;
}

.textareaBox .spanText span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9E9E9E;
    display: inline-block;
    padding-left: 5px;
}

@keyframes rotate  {
    100% {
        transform: rotate(360deg);
    }
}