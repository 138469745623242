.lecture_card_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}


@media all and (max-width: 768px) {
    .lecture_card_thumbnail {
        height: 180px !important;
    }



    .lecture_card_title {
        -webkit-line-clamp: 1 !important;
        font-size: 16px !important;
        height: auto !important;
    }

    
    .lecture_card_info {
        flex-direction: column;
    }
    .lecture_card_info li:nth-child(1) {
        display: none;
    }
    .lecture_card_info li:nth-child(2) {
        display: none;
    }
}