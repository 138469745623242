.bill_container .bill-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}
.bill_container .bill-table th, .bill-table td {
    padding: 10px;
    border: 1px solid #000;
    font-size: 18px;
}
.bill_container .bill-table th {
    width: 100px;
    background: #BDBDBD;
}
.bill_container > p.note_txt {
    font-size: 16px;
    padding: 0 10px;
}
.bill_container .bill-info > div {
    padding: 10px 0;
}
.bill_container .bill-info > div p {
    font-size: 18px;
}