.file-layout {
    width: 100%;
    height: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.file-layout button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    border-radius: 4px;
    text-align: left;
    padding: 10px;
}