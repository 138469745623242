.file-layout {
    display: flex;
    align-items: center;
}

.file-layout input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 0 10px;
    border-right: none;
}

.file-layout label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    cursor: pointer;
}

.file-layout-background .span-text {
    margin-top: 5px;
    height: 16px;
    display: flex;
    align-items: center;
}

.file-layout-background .span-text p {
    font-size: 12px;
}

.file-layout-background .span-text span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9E9E9E;
    display: inline-block;
    padding-left: 5px;
}

@keyframes rotate  {
    100% {
        transform: rotate(360deg);
    }
}