.program_layout {
    width: 100%;
}
.program_layout th, .program_layout td {
    width: calc(100% / 6);
    border: 1px solid #000;
}
.program_layout th {
    background: #E7EDF7;
    height: 30px
}
.program_layout td {
    height: 50px;
    padding: 5px;
}
.program_layout td pre {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #333;
    white-space: pre-wrap;
    word-break: break-all;
    overflow: auto;
}
.program_layout td p a {
    font-size: 18px;
    word-break: break-all;
}
.program_layout td p img {
    width: 45%;
}