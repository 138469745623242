.board_article_container {
    width: 100%;
    margin-top: 50px;
}

.board_article_container ul {
    display: flex; flex-direction: column;
    margin: 0;
    border-top: 2px solid #AB5476; border-bottom: 1px solid #AB5476;
}
.board_article_container ul li {
    display: flex; justify-content: flex-start; align-items: center;
}
.board_article_container ul li:first-child {
    border-bottom: 1px solid #EEEEEE;
}
.board_article_container ul li h4 {
    margin: 0; padding: 20px;
    background: #FFFBFA;
    font-size: 16px; font-weight: 700;
    word-break: keep-all;
}
.board_article_container ul li p {
    padding: 20px;
    width: 100%;
}


@media all and (max-width: 768px) {
    .board_article_container {
        margin-top: 30px;
    }
    .board_article_container ul li h4 {
        padding: 10px 20px;
        font-size: 16px;
    }
    .board_article_container ul li p {
        padding: 10px 20px;
    }
}