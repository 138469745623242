.category-layout {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
}

.category-layout > ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    /* padding-right: 42px; */
    overflow-x: scroll;
    /* Firefox */
    scrollbar-width: none; 
    /* IE, Edge */
    -ms-overflow-style: none; 
}
.category-layout > ul::-webkit-scrollbar {
    width: 0px;
}
.category-layout > ul::-webkit-scrollbar-track {
    background-color: none;
}
.category-layout > ul::-webkit-scrollbar-thumb {
    background-color: none;
}

.category-layout .gradient {
    width: 15px; height: 42px;
    position: absolute; top: 0;
}
.category-layout .gradient.left-gradient {
    left: 0;
    background-image: linear-gradient(90deg, #fff 20%,hsla(0,0%,100%,0) 80%);
}
.category-layout .gradient.right-gradient {
    right: 42px;
    background-image: linear-gradient(90deg, hsla(0,0%,100%,0) 20%,#fff 80%);
}

.category-layout > ul li {
    width: 100%;
    cursor: pointer;
    margin-right: 15px; 
}

.category-layout > ul li:last-child {
    margin-right: 0;
}

.category-layout > ul li .category_radio_button {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-layout > ul li .category_radio_input {
    display: none;
}

.category-layout > ul li .category_radio_input:checked + .category_radio_label {
    color: #AB5476;
    border: 1px solid #AB5476;
    background-color: #F2D3DB;
}

.category-layout > ul li .category_radio_label {
    border: 1px solid #9999A3;
    display: block;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    padding: 10px 25px;
    text-transform: capitalize;
    cursor: pointer;
    gap: 10px;
    white-space: nowrap;
}

.category-layout > ul li .category_radio_label:hover {
    color: #AB5476;
    border: 1px solid #AB5476;
    background-color: #F2D3DB;
}



.category-layout .more-view {
    position: absolute;
    top: 0; right: 0;
    height: 42px; width: 42px;
    background-color: #FFFFFF;
    display: flex; justify-content: center; align-items: center;
}
.category-layout .more-view div {
    display: flex; justify-content: center; align-items: center;
    border-radius: 50%; border: 1px solid #BDBDBD;
    width: 35px; height: 35px;
    cursor: pointer;
}

.category-layout .more-view-item-layout {
    position: absolute; top: 110%;
    width: 100%;
    z-index: 500;
    background-color: #FFFFFF;
    padding: 30px;
    border: 1px solid #BDBDBD; border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);
}
.category-layout .more-view-item-layout ul {
    display: flex; flex-wrap: wrap;
}
.category-layout .more-view-item-layout ul li {
    cursor: pointer; margin-right: 15px; margin-bottom: 15px;
}
.category-layout .more-view-item-layout ul li .category_radio_input {
    display: none;
}

.category-layout .more-view-item-layout ul li .category_radio_input:checked + .category_radio_label {
    color: #AB5476;
    border: 1px solid #AB5476;
    background-color: #F2D3DB;
}
.category-layout .more-view-item-layout ul li .category_radio_label {
    border: 1px solid #9999A3;
    display: block;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    padding: 10px 25px;
    text-transform: capitalize;
    cursor: pointer;
    gap: 10px;
    white-space: nowrap;
}

.category-layout .more-view-item-layout ul li .category_radio_label:hover {
    color: #AB5476;
    border: 1px solid #AB5476;
    background-color: #F2D3DB;
}



@media all and (max-width: 768px) {
    .category-layout > ul li {
        margin-right: 10px;
    }
    .category-layout > ul li .category_radio_label {
        padding: 8px 15px;
        font-size: 16px;
    }


    .category-layout .more-view-item-layout {
        padding: 15px;
    }
    .category-layout .more-view-item-layout ul li {
        margin-right: 5px; margin-bottom: 5px;
    }
    .category-layout .more-view-item-layout ul li .category_radio_label {
        padding: 8px 15px;
        font-size: 16px;
    }

    .lecture_card_event {
        width: 45px !important; height: 45px !important;
        font-size: 12px !important;
    }


    .category-layout .more-view {
        width: 38px; height: 38px;
    }


    .category-layout .gradient.right-gradient {
        right: 38px;
    }
}