.datepicker-layout {
  width: 100%;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.datepicker-layout input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  cursor: pointer;
}
