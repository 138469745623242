.statistic_container {
    padding: 24px; margin: 10px;
    border: 1px solid #F5F5F5; border-radius: 10px;
    display: flex; justify-content: center; align-items: center;
    flex-direction: column;
    width: 120px;
}
.statistic_container h4 {
    color: #AB5476;
    font-weight: 700;
    font-size: 20px;
}
.statistic_container p {
    font-size: 24px;
    font-weight: 700;
    display: flex; align-items: center;
}
.statistic_container p span {
    font-size: 20px;
    font-weight: 400;
}