.tab-layout .tabs {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #9E9E9E;
    margin: 0;
    overflow-y: scroll;
}
.tabs li {
    width: 128px;
    display: flex;
    height: 52px;
    align-items: center;
    justify-content: center;
    margin-right: 48px;
}
.tabs li div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tabs li div .tab_radio_label {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    white-space: nowrap;
}
.tabs li .tab_radio_input {
    display: none;
}
.tabs li .tab_radio_input:checked + .tab_radio_label {
    color: #AB5476;
    border-bottom: 2px solid #AB5476;
}