#tax_bill_templete {
    margin: 0 auto;
}

@media all and (max-width: 768px) {
    #teacher_bill_templete td {
        font-size: 12px !important;
        
    }
    #teacher_bill_templete td:nth-child(2n - 1) {
        width: 20% !important;
        min-width: none !important;
    }
    #teacher_bill_templete td:nth-child(2n) {
        width: 30% !important;
    }



    #tax_bill_templete {
        overflow-x: scroll;
    }
}